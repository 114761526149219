import { EmojiData } from "emoji-mart";

export const customEmojis = [
  {
    name: "heart",
    short_names: ["heart", "<3"],
    keywords: ["heart", "<3"],
    imageUrl: "/emotes/heart.png",
  },
  {
    name: "brokenHeart",
    short_names: ["brokenHeart"],
    keywords: ["broken", "heart", "broken heart", "brokenHeart"],
    imageUrl: "/emotes/brokenHeart.gif",
  },
  {
    name: "obamium",
    short_names: ["obama"],
    keywords: ["obama", "prism", "obamium"],
    imageUrl: "/emotes/obamium.png",
  },
  {
    name: "IntelxAMD",
    short_names: ["intel"],
    keywords: ["intel", "amd", "intelxamd"],
    imageUrl: "/emotes/IntelxAMD.png",
  },
  {
    name: "linus",
    short_names: ["linus"],
    keywords: ["linus", "tech", "linustechtips"],
    imageUrl: "/emotes/linus.png",
  },
  {
    name: "redDogeHouse",
    short_names: ["redDogeHouse"],
    keywords: ["red", "dogehouse", "doge"],
    imageUrl: "/emotes/reddogehouse.png",
  },
  {
    name: "this",
    short_names: ["this"],
    keywords: ["this"],
    imageUrl: "/emotes/this.png",
  },
  {
    name: "brownDogeHouse",
    short_names: ["brownDogeHouse"],
    keywords: ["brown", "dogehouse", "doge"],
    imageUrl: "/emotes/browndogehouse.png",
  },
  {
    name: "shut",
    short_names: ["shut"],
    keywords: ["shut"],
    imageUrl: "/emotes/shut.png",
  },
  {
    name: "blobWtf",
    short_names: ["blobWtf"],
    keywords: ["blob", "wtf"],
    imageUrl: "/emotes/blobwtf.png",
  },
  {
    name: "WhaleThonk",
    short_names: ["WhaleThonk"],
    keywords: ["whale", "thonk", "thinking", "what"],
    imageUrl: "/emotes/whalethonk.png",
  },
  {
    name: "PogChamp",
    short_names: ["PogChamp"],
    keywords: ["pogchamp", "pog"],
    imageUrl: "/emotes/pogchamp.png",
  },
  {
    name: "monkaS",
    short_names: ["monkaS"],
    keywords: ["monkas", "pepe"],
    imageUrl: "/emotes/monkaS.png",
  },
  {
    name: "HYPERS",
    short_names: ["HYPERS"],
    keywords: ["hypers", "pepe"],
    imageUrl: "/emotes/HYPERS.png",
  },
  {
    name: "pepeD",
    short_names: ["pepeD"],
    keywords: ["peped", "pepe"],
    imageUrl: "/emotes/pepeD.gif",
  },
  {
    name: "Pepega",
    short_names: ["Pepega"],
    keywords: ["pepega", "pepe"],
    imageUrl: "/emotes/Pepega.png",
  },
  {
    name: "peepoHappy",
    short_names: ["peepoHappy"],
    keywords: ["peepohappy", "peepo", "pepe"],
    imageUrl: "/emotes/peepoHappy.png",
  },
  {
    name: "peepoHug",
    short_names: ["peepoHug"],
    keywords: ["peepohug", "peepo", "pepe"],
    imageUrl: "/emotes/peepoHug.png",
  },
  {
    name: "Sadge",
    short_names: ["Sadge"],
    keywords: ["sadge", "pepe"],
    imageUrl: "/emotes/Sadge.png",
  },
  {
    name: "catJAM",
    short_names: ["catJAM"],
    keywords: ["catjam", "vibe"],
    imageUrl: "/emotes/catJAM.gif",
  },
  {
    name: "Thonk",
    short_names: ["Thonk"],
    keywords: ["thonk", "think"],
    imageUrl: "/emotes/Thonk.png",
  },
  {
    name: "DogeHouse",
    short_names: ["DogeHouse"],
    keywords: ["dogehouse", "doge"],
    imageUrl: "/emotes/DogeHouse.png",
  },
  {
    name: "SadHouse",
    short_names: ["SadHouse"],
    keywords: ["dogehouse", "doge", "sadhouse"],
    imageUrl: "/emotes/SadHouse.png",
  },
  {
    name: "CoolHouse",
    short_names: ["CoolHouse"],
    keywords: ["dogehouse", "doge", "coolhouse"],
    imageUrl: "/emotes/CoolHouse.png",
  },
  {
    name: "WinkHouse",
    short_names: ["WinkHouse"],
    keywords: ["dogehouse", "doge", "winkhouse"],
    imageUrl: "/emotes/WinkHouse.png",
  },
  {
    name: "SupriseHouse",
    short_names: ["SupriseHouse"],
    keywords: ["dogehouse", "doge", "suprisehouse", "shock"],
    imageUrl: "/emotes/SupriseHouse.png",
  },
  {
    name: "NeutralHouse",
    short_names: ["NeutralHouse"],
    keywords: ["dogehouse", "doge", "neutralhouse"],
    imageUrl: "/emotes/NeutralHouse.png",
  },
  {
    name: "WAYTOODANK",
    short_names: ["WAYTOODANK"],
    keywords: ["dank", "feelsdankman", "waytoodank"],
    imageUrl: "/emotes/WAYTOODANK.gif",
  },
  {
    name: "CryptoBTC",
    short_names: ["CryptoBTC"],
    keywords: ["crypto", "btc", "bitcoin"],
    imageUrl: "/emotes/CryptoBTC.png",
  },
  {
    name: "CryptoETH",
    short_names: ["CryptoETH"],
    keywords: ["crypto", "eth", "ethereum"],
    imageUrl: "/emotes/CryptoETH.png",
  },
  {
    name: "CryptoBNB",
    short_names: ["CryptoBNB"],
    keywords: ["crypto", "bnb", "binance"],
    imageUrl: "/emotes/CryptoBNB.png",
  },
  {
    name: "CryptoLTC",
    short_names: ["CryptoLTC"],
    keywords: ["crypto", "ltc", "litecoin"],
    imageUrl: "/emotes/CryptoLTC.png",
  },
  {
    name: "CryptoBCH",
    short_names: ["CryptoBCH"],
    keywords: ["crypto", "bch", "bitcoin", "bitcoincash"],
    imageUrl: "/emotes/CryptoBCH.png",
  },
  {
    name: "CryptoDOGE",
    short_names: ["CryptoDOGE"],
    keywords: ["crypto", "doge", "dogecoin", "bestcoin"],
    imageUrl: "/emotes/CryptoDOGE.png",
  },
  {
    name: "CryptoSUSHI",
    short_names: ["CryptoSUSHI"],
    keywords: ["crypto", "sushi", "swap", "sushiswap"],
    imageUrl: "/emotes/CryptoSUSHI.png",
  },
  {
    name: "CryptoTRON",
    short_names: ["CryptoTRON"],
    keywords: ["crypto", "tron"],
    imageUrl: "/emotes/cryptoTron.png",
  },
  {
    name: "CryptoZEC",
    short_names: ["CryptoZEC"],
    keywords: ["crypto", "zec", "zcash"],
    imageUrl: "/emotes/CryptoZEC.png",
  },
  {
    name: "CryptoETC",
    short_names: ["CryptoETC"],
    keywords: ["crypto", "etc", "ethereum", "ethereumclassic"],
    imageUrl: "/emotes/CryptoETC.png",
  },
  {
    name: "CryptoCAKE",
    short_names: ["CryptoCAKE"],
    keywords: ["crypto", "cake", "swap", "pancakeswap"],
    imageUrl: "/emotes/CryptoCAKE.png",
  },
  {
    name: "CryptoADA",
    short_names: ["CryptoADA"],
    keywords: ["crypto", "ada", "cardano"],
    imageUrl: "/emotes/CryptoADA.png",
  },
  {
    name: "CryptoXRP",
    short_names: ["CryptoXRP"],
    keywords: ["crypto", "xrp", "ripple"],
    imageUrl: "/emotes/CryptoXRP.png",
  },
  {
    name: "CryptoUSDC",
    short_names: ["CryptoUSDC"],
    keywords: ["crypto", "usdc", "usdcoin"],
    imageUrl: "/emotes/CryptoUSDC.png",
  },
  {
    name: "DodgyCoin",
    short_names: ["DodgyCoin"],
    keywords: ["crypto", "doge", "dodgycoin"],
    imageUrl: "/emotes/DodgyCoin.png",
  },
  {
    name: "pepeBCKL",
    short_names: ["pepeBCKL"],
    keywords: [
      "pepebckl",
      "bckl",
      "pepe",
      "malarkey",
      "jesse",
      "penguin",
      "stallman",
      "freesoftware",
      "fsf",
      "charlie",
      "kernel",
      "lab",
      "kernellab",
      "computing",
      "jessecharlie",
      "linux",
      "torvalds",
    ],
    imageUrl: "/emotes/pepeBCKL.png",
  },
  {
    name: "5Head",
    short_names: ["5Head"],
    keywords: ["5head", "big", "brain", "bigbrain", "smart"],
    imageUrl: "/emotes/5Head.png",
  },
  {
    name: "AYAYA",
    short_names: ["AYAYA"],
    keywords: ["ayaya"],
    imageUrl: "/emotes/AYAYA.png",
  },
  {
    name: "babaYEP",
    short_names: ["babaYEP"],
    keywords: ["babayep", "baba", "yep"],
    imageUrl: "/emotes/babaYEP.png",
  },
  {
    name: "BBoomer",
    short_names: ["BBoomer"],
    keywords: ["bboomer", "boomer"],
    imageUrl: "/emotes/BBoomer.gif",
  },
  {
    name: "BebeLa",
    short_names: ["BebeLa"],
    keywords: ["bebela", "bebe"],
    imageUrl: "/emotes/BebeLa.png",
  },
  {
    name: "BERN",
    short_names: ["BERN"],
    keywords: [
      "bern",
      "notmeus",
      "bernie",
      "sanders",
      "berniesanders",
      "bernard",
      "socialism",
      "socialist",
      "comrade",
      "democrat",
      "malarkey",
      "biden",
      "joe",
      "joebiden",
      "us",
      "usa",
      "america",
    ],
    imageUrl: "/emotes/BERN.png",
  },
  {
    name: "bidenJAM",
    short_names: ["bidenJAM"],
    keywords: [
      "bidenjam",
      "jam",
      "jamjam",
      "malarkey",
      "ridinwithbiden",
      "icecream",
      "joe",
      "biden",
      "joebiden",
      "46",
      "president",
      "laser",
      "potus",
      "democrat",
      "us",
      "usa",
      "america",
    ],
    imageUrl: "/emotes/bidenJAM.gif",
  },
  {
    name: "Playa",
    short_names: ["Playa"],
    keywords: ["Playa"],
    imageUrl: "/emotes/Playa.png",
  },
  {
    name: "IGetIt",
    short_names: ["IGetIt"],
    keywords: ["IGetIt", "Smile", "PepeLa", "Smug"],
    imageUrl: "/emotes/IGetIt.png",
  },
  {
    name: "BLANKIES",
    short_names: ["BLANKIES"],
    keywords: ["blankies", "jammies", "blanket", "dance"],
    imageUrl: "/emotes/BLANKIES.gif",
  },
  {
    name: "BOGGED",
    short_names: ["BOGGED"],
    keywords: [
      "bogged",
      "bog",
      "hello",
      "phone",
      "iphone",
      "calling",
      "call",
      "phonecall",
    ],
    imageUrl: "/emotes/BOGGED.png",
  },
  {
    name: "Clap",
    short_names: ["Clap"],
    keywords: ["clap", "clapping"],
    imageUrl: "/emotes/Clap.gif",
  },
  {
    name: "COGGERS",
    short_names: ["COGGERS"],
    keywords: ["coggers", "pog", "pogu", "poggies", "poggers", "pogger"],
    imageUrl: "/emotes/COGGERS.gif",
  },
  {
    name: "COPIUM",
    short_names: ["COPIUM"],
    keywords: ["copium", "cope"],
    imageUrl: "/emotes/COPIUM.png",
  },
  {
    name: "coronaS",
    short_names: ["coronaS"],
    keywords: ["coronas", "corona", "mask", "covid"],
    imageUrl: "/emotes/coronaS.png",
  },
  {
    name: "CrabPls",
    short_names: ["CrabPls"],
    keywords: ["crabpls", "crab", "pls", "plz", "please"],
    imageUrl: "/emotes/crabpls.gif",
  },
  {
    name: "DANKHACKERMANS",
    short_names: ["DANKHACKERMANS"],
    keywords: [
      "dankhackermans",
      "dank",
      "hacker",
      "hackermans",
      "keyboard",
      "typing",
      "computer",
      "coding",
      "coder",
      "code",
      "virus",
      "malware",
    ],
    imageUrl: "/emotes/DANKHACKERMANS.gif",
  },
  {
    name: "dankHug",
    short_names: ["dankHug"],
    keywords: ["dankhug", "dank", "hug"],
    imageUrl: "/emotes/dankHug.png",
  },
  {
    name: "DANKIES",
    short_names: ["DANKIES"],
    keywords: ["dankies", "dank"],
    imageUrl: "/emotes/DANKIES.gif",
  },
  {
    name: "DewTime",
    short_names: ["DewTime"],
    keywords: ["dewtime", "mountaindew", "dew", "moutain", "time"],
    imageUrl: "/emotes/DewTime.gif",
  },
  {
    name: "EZ",
    short_names: ["EZ"],
    keywords: ["ez"],
    imageUrl: "/emotes/EZ.png",
  },
  {
    name: "F",
    short_names: ["F"],
    keywords: ["f"],
    imageUrl: "/emotes/F.gif",
  },
  {
    name: "FeelsDankMan",
    short_names: ["FeelsDankMan"],
    keywords: ["feelsdankman", "feels", "feel", "dank", "man"],
    imageUrl: "/emotes/FeelsDankMan.png",
  },
  {
    name: "FeelsOkayMan",
    short_names: ["FeelsOkayMan"],
    keywords: ["feelsokayman", "feels", "feel", "okay", "man"],
    imageUrl: "/emotes/FeelsOkayMan.png",
  },
  {
    name: "FeelsStrongMan",
    short_names: ["FeelsStrongMan"],
    keywords: ["feelsstrongman", "feels", "feel", "strong", "man"],
    imageUrl: "/emotes/FeelsStrongMan.png",
  },
  {
    name: "FeelsWeirdMan",
    short_names: ["FeelsWeirdMan"],
    keywords: ["feelsweirdman", "feels", "feel", "weird", "man"],
    imageUrl: "/emotes/FeelsWeirdMan.png",
  },
  {
    name: "gachiHYPER",
    short_names: ["gachiHYPER"],
    keywords: ["gachihyper", "gachi", "hyper", "coomer"],
    imageUrl: "/emotes/gachiHYPER.gif",
  },
  {
    name: "GRUG",
    short_names: ["GRUG"],
    keywords: ["grug"],
    imageUrl: "/emotes/GRUG.png",
  },
  {
    name: "GuitarTime",
    short_names: ["GuitarTime"],
    keywords: ["guitartime", "guitar", "time"],
    imageUrl: "/emotes/GuitarTime.gif",
  },
  {
    name: "HACKERMANS",
    short_names: ["HACKERMANS"],
    keywords: [
      "hackermans",
      "hacker",
      "keyboard",
      "typing",
      "computer",
      "coding",
      "coder",
      "code",
      "virus",
      "malware",
    ],
    imageUrl: "/emotes/HACKERMANS.gif",
  },
  {
    name: "HandsUp",
    short_names: ["HandsUp"],
    keywords: ["handsup", "hands", "up", "hand"],
    imageUrl: "/emotes/HandsUp.png",
  },
  {
    name: "hasanHyperSmash",
    short_names: ["hasanHyperSmash"],
    keywords: [
      "hasanhypersmash",
      "hasan",
      "hasanabi",
      "hasanpiker",
      "hyper",
      "smash",
    ],
    imageUrl: "/emotes/hasanHyperSmash.gif",
  },
  {
    name: "hasanSmash",
    short_names: ["hasanSmash"],
    keywords: ["hasansmash", "hasan", "hasanabi", "hasanpiker", "smash"],
    imageUrl: "/emotes/hasanSmash.gif",
  },
  {
    name: "HasanWalk",
    short_names: ["HasanWalk"],
    keywords: [
      "hasanwalk",
      "hasan",
      "hasanabi",
      "hasanpiker",
      "walk",
      "walking",
    ],
    imageUrl: "/emotes/HasanWalk.gif",
  },
  {
    name: "hasHyperJAM",
    short_names: ["hasHyperJAM"],
    keywords: [
      "hashyperjam",
      "hasan",
      "hasanabi",
      "hasanpiker",
      "hyper",
      "jam",
      "jamjam",
      "jammies",
    ],
    imageUrl: "/emotes/hasHyperJAM.gif",
  },
  {
    name: "hasPls",
    short_names: ["hasPls"],
    keywords: [
      "haspls",
      "please",
      "pls",
      "plz",
      "hasan",
      "hasanabi",
      "hasanpiker",
    ],
    imageUrl: "/emotes/hasPls.gif",
  },
  {
    name: "hasRock",
    short_names: ["hasRock"],
    keywords: [
      "hasrock",
      "spongebob",
      "patrick",
      "hasan",
      "hasanabi",
      "hasanpiker",
      "rock",
    ],
    imageUrl: "/emotes/hasRock.gif",
  },
  {
    name: "hasTasty",
    short_names: ["hasTasty"],
    keywords: [
      "hastasty",
      "hasan",
      "tasty",
      "yum",
      "yummy",
      "hasanabi",
      "hasanpiker",
    ],
    imageUrl: "/emotes/hasTasty.gif",
  },
  {
    name: "HYPERCLAP",
    short_names: ["HYPERCLAP"],
    keywords: ["hyperclap", "hyper", "clap"],
    imageUrl: "/emotes/HYPERCLAP.gif",
  },
  {
    name: "hyperHammer",
    short_names: ["hyperHammer"],
    keywords: ["hyperhammer", "hyper", "hammer"],
    imageUrl: "/emotes/hyperHammer.gif",
  },
  {
    name: "HYPERITALIANHANDS",
    short_names: ["HYPERITALIANHANDS"],
    keywords: [
      "hyperitalianhands",
      "hyper",
      "italian",
      "hands",
      "hand",
      "italy",
      "ovahere",
    ],
    imageUrl: "/emotes/HYPERITALIANHANDS.gif",
  },
  {
    name: "HYPERPOGGER",
    short_names: ["HYPERPOGGER"],
    keywords: ["hyperpogger", "hyper", "pog", "pogger", "poggies"],
    imageUrl: "/emotes/HYPERPOGGER.gif",
  },
  {
    name: "HYPERPOGO",
    short_names: ["HYPERPOGO"],
    keywords: [
      "hyperpogo",
      "hyper",
      "pog",
      "pogo",
      "azan",
      "poggies",
      "poggers",
      "pogger",
    ],
    imageUrl: "/emotes/HYPERPOGO.gif",
  },
  {
    name: "ItalianHands",
    short_names: ["ItalianHands"],
    keywords: ["italianhands", "ovahere", "italian", "hands", "hand", "italy"],
    imageUrl: "/emotes/ItalianHands.gif",
  },
  {
    name: "Jammies",
    short_names: ["Jammies"],
    keywords: ["jammies", "jam", "jamjam", "dance", "vibe"],
    imageUrl: "/emotes/Jammies.gif",
  },
  {
    name: "KEKebab",
    short_names: ["KEKebab"],
    keywords: [
      "kekebab",
      "kek",
      "kebab",
      "omegalul",
      "lol",
      "lmao",
      "haha",
      "ha",
      "ja",
      "jaja",
      "kekw",
    ],
    imageUrl: "/emotes/KEKebab.png",
  },
  {
    name: "KEKW",
    short_names: ["KEKW"],
    keywords: [
      "kekw",
      "kek",
      "omegalul",
      "lol",
      "lmao",
      "haha",
      "ha",
      "ja",
      "jaja",
    ],
    imageUrl: "/emotes/KEKW.png",
  },
  {
    name: "KEKWait",
    short_names: ["KEKWait"],
    keywords: [
      "kekwait",
      "lul",
      "omegalul",
      "lol",
      "lmao",
      "wait",
      "kek",
      "ha",
    ],
    imageUrl: "/emotes/KEKWait.png",
  },
  {
    name: "KKapitalist",
    short_names: ["KKapitalist"],
    keywords: ["kkapitalist", "kapitalist", "capitalist", "business"],
    imageUrl: "/emotes/KKapitalist.png",
  },
  {
    name: "KKomrade",
    short_names: ["KKomrade"],
    keywords: ["kkomrade", "komrade", "comrade"],
    imageUrl: "/emotes/KKomrade.png",
  },
  {
    name: "KKonaW",
    short_names: ["KKonaW"],
    keywords: ["kkonaw"],
    imageUrl: "/emotes/KKonaW.png",
  },
  {
    name: "KKop",
    short_names: ["KKop"],
    keywords: [
      "kkop",
      "kop",
      "kops",
      "cop",
      "cops",
      "police",
      "acab",
      "blueline",
    ],
    imageUrl: "/emotes/KKop.png",
  },
  {
    name: "LULW",
    short_names: ["LULW"],
    keywords: ["lulw", "lul", "lol", "lmao", "omegalul"],
    imageUrl: "/emotes/LULW.png",
  },
  {
    name: "lulWut",
    short_names: ["lulWut"],
    keywords: [
      "lulwut",
      "lul",
      "lmao",
      "lol",
      "wut",
      "what",
      "wat",
      "wtf",
      "omegalul",
      "ha",
      "haha",
      "ja",
      "jaja",
    ],
    imageUrl: "/emotes/lulWut.png",
  },
  {
    name: "MALARKEY",
    short_names: ["MALARKEY"],
    keywords: [
      "malarkey",
      "ridinwithbiden",
      "icecream",
      "joe",
      "biden",
      "joebiden",
      "46",
      "president",
      "laser",
      "potus",
      "democrat",
      "us",
      "usa",
      "america",
    ],
    imageUrl: "/emotes/MALARKEY.gif",
  },
  {
    name: "MmmHmm",
    short_names: ["MmmHmm"],
    keywords: [
      "mmmhmm",
      "mmm",
      "mmmm",
      "hmm",
      "hmmm",
      "mmmmhmmm",
      "mmmmhmm",
      "mmmhmmm",
    ],
    imageUrl: "/emotes/MmmHmm.gif",
  },
  {
    name: "modCheck",
    short_names: ["modCheck"],
    keywords: [
      "modcheck",
      "mods",
      "mod",
      "check",
      "spongebob",
      "ask",
      "askers",
      "whoasked",
      "where",
      "look",
      "fish",
      "oooo",
    ],
    imageUrl: "/emotes/modCheck.gif",
  },
  {
    name: "ModTime",
    short_names: ["ModTime"],
    keywords: ["modtime", "mod", "mods", "time"],
    imageUrl: "/emotes/ModTime.gif",
  },
  {
    name: "monkaHmm",
    short_names: ["monkaHmm"],
    keywords: ["monkahmm", "monka", "hmmm", "hmm"],
    imageUrl: "/emotes/monkaHmm.png",
  },
  {
    name: "monkaStare",
    short_names: ["monkaStare"],
    keywords: ["monkastare", "stare", "monka"],
    imageUrl: "/emotes/monkaStare.png",
  },
  {
    name: "monkaSTEER",
    short_names: ["monkaSTEER"],
    keywords: ["monkasteer", "monka", "steer", "ridinwithbiden"],
    imageUrl: "/emotes/monkaSTEER.gif",
  },
  {
    name: "monkaW",
    short_names: ["monkaW"],
    keywords: ["monkaw", "monka"],
    imageUrl: "/emotes/monkaW.png",
  },
  {
    name: "neffHyperJAM",
    short_names: ["neffHyperJAM"],
    keywords: [
      "neffhyperjam",
      "hyperjam",
      "jam",
      "jammies",
      "boppin",
      "dj",
      "jamjam",
      "neff",
      "will",
      "willneff",
    ],
    imageUrl: "/emotes/neffHyperJAM.gif",
  },
  {
    name: "NODDERS",
    short_names: ["NODDERS"],
    keywords: ["nodders", "nod", "noddies"],
    imageUrl: "/emotes/NODDERS.gif",
  },
  {
    name: "NOPERS",
    short_names: ["NOPERS"],
    keywords: ["nopers", "no", "nope"],
    imageUrl: "/emotes/NOPERS.gif",
  },
  {
    name: "nymnCorn",
    short_names: ["nymnCorn"],
    keywords: ["nymncorn", "mmm", "corn", "nymn"],
    imageUrl: "/emotes/nymnCorn.gif",
  },
  {
    name: "OkayChamp",
    short_names: ["OkayChamp"],
    keywords: ["okaychamp", "okay", "champ"],
    imageUrl: "/emotes/OkayChamp.png",
  },
  {
    name: "OMEGALUL",
    short_names: ["OMEGALUL"],
    keywords: ["omegalul", "lol", "lmao", "lul", "ha", "haha", "ja", "jaja"],
    imageUrl: "/emotes/omegalul.png",
  },
  {
    name: "OOOO",
    short_names: ["OOOO"],
    keywords: ["oooo", "fish"],
    imageUrl: "/emotes/OOOO.gif",
  },
  {
    name: "PagChomp",
    short_names: ["PagChomp"],
    keywords: ["pagchomp", "pag", "chomp"],
    imageUrl: "/emotes/PagChomp.png",
  },
  {
    name: "PainsChamp",
    short_names: ["PainsChamp"],
    keywords: ["painschamp", "pains", "champ", "pain"],
    imageUrl: "/emotes/PainsChamp.png",
  },
  {
    name: "PauseChamp",
    short_names: ["PauseChamp"],
    keywords: ["pausechamp", "pause", "champ"],
    imageUrl: "/emotes/PauseChamp.png",
  },
  {
    name: "peepoArrive",
    short_names: ["peepoArrive"],
    keywords: [
      "peepoarrive",
      "peepo",
      "pepo",
      "arrive",
      "here",
      "welcome",
      "hey",
      "wave",
      "hi",
      "hello",
      "howdy",
    ],
    imageUrl: "/emotes/peepoArrive.gif",
  },
  {
    name: "peepoBaba",
    short_names: ["peepoBaba"],
    keywords: ["peepobaba", "peepo", "pepo", "baba"],
    imageUrl: "/emotes/peepoBaba.png",
  },
  {
    name: "peepoBye",
    short_names: ["peepoBye"],
    keywords: ["peepobye", "peepo", "pepo", "bye"],
    imageUrl: "/emotes/peepoBye.gif",
  },
  {
    name: "peepoChat",
    short_names: ["peepoChat"],
    keywords: ["peepochat", "peepo", "pepo", "chat"],
    imageUrl: "/emotes/peepoChat.gif",
  },
  {
    name: "peepoCheer",
    short_names: ["peepoCheer"],
    keywords: ["peepocheer", "peepo", "pepo", "cheer"],
    imageUrl: "/emotes/peepoCheer.gif",
  },
  {
    name: "peepoClap",
    short_names: ["peepoClap"],
    keywords: ["peepoclap", "peepo", "pepo", "clap"],
    imageUrl: "/emotes/peepoClap.gif",
  },
  {
    name: "peepoD",
    short_names: ["peepoD"],
    keywords: ["peepod", "peepo", "pepo"],
    imageUrl: "/emotes/peepoD.gif",
  },
  {
    name: "peepoFat",
    short_names: ["peepoFat"],
    keywords: ["peepofat", "peepo", "pepo", "fat", "houngry", "hungry", "food"],
    imageUrl: "/emotes/peepoFat.png",
  },
  {
    name: "peepoGiggles",
    short_names: ["peepoGiggles"],
    keywords: ["peepogiggles", "pepo", "peepo", "giggle", "giggles"],
    imageUrl: "/emotes/peepoGiggles.gif",
  },
  {
    name: "peepoHey",
    short_names: ["peepoHey"],
    keywords: [
      "peepohey",
      "peepo",
      "wave",
      "pepo",
      "hey",
      "hi",
      "hello",
      "howdy",
      "welcome",
    ],
    imageUrl: "/emotes/peepoHey.gif",
  },
  {
    name: "peepoJAMMER",
    short_names: ["peepoJAMMER"],
    keywords: ["peepojammer", "peepo", "pepo", "jam", "jammer", "jamjam"],
    imageUrl: "/emotes/peepoJAMMER.gif",
  },
  {
    name: "peepoKiss",
    short_names: ["peepoKiss"],
    keywords: ["peepokiss", "peepo", "pepo", "kiss", "love"],
    imageUrl: "/emotes/peepoKiss.png",
  },
  {
    name: "peepoLeave",
    short_names: ["peepoLeave"],
    keywords: ["peepoleave", "peepo", "pepo", "leave", "exit", "bye"],
    imageUrl: "/emotes/peepoLeave.gif",
  },
  {
    name: "peepoPog",
    short_names: ["peepoPog"],
    keywords: ["peepopog", "peepo", "pepo", "pog"],
    imageUrl: "/emotes/peepoPog.png",
  },
  {
    name: "peepoPogO",
    short_names: ["peepoPogO"],
    keywords: ["peepopogo", "peepo", "pepo", "pog", "pogo"],
    imageUrl: "/emotes/peepoPogO.png",
  },
  {
    name: "peepoRun",
    short_names: ["peepoRun"],
    keywords: ["peeporun", "peepo", "pepo", "run"],
    imageUrl: "/emotes/peepoRun.gif",
  },
  {
    name: "peepoShy",
    short_names: ["peepoShy"],
    keywords: ["peeposhy", "peepo", "pepo", "shy"],
    imageUrl: "/emotes/peepoShy.gif",
  },
  {
    name: "peepoSnow",
    short_names: ["peepoSnow"],
    keywords: [
      "peeposnow",
      "peepo",
      "pepo",
      "snow",
      "christmas",
      "cold",
      "holidays",
    ],
    imageUrl: "/emotes/peepoSnow.gif",
  },
  {
    name: "peepoT",
    short_names: ["peepoT"],
    keywords: ["peepot", "peepo", "pepo"],
    imageUrl: "/emotes/peepoT.gif",
  },
  {
    name: "peepoWeird",
    short_names: ["peepoWeird"],
    keywords: ["peepoweird", "peepo", "pepo", "weird"],
    imageUrl: "/emotes/peepoWeird.png",
  },
  {
    name: "pepeCD",
    short_names: ["pepeCD"],
    keywords: ["pepecd", "pepe", "cd"],
    imageUrl: "/emotes/pepeCD.gif",
  },
  {
    name: "PepegeHmm",
    short_names: ["PepegeHmm"],
    keywords: ["pepegehmm", "pepege", "pepe", "hmm", "hmmm"],
    imageUrl: "/emotes/PepegeHmm.png",
  },
  {
    name: "PepeHands",
    short_names: ["PepeHands"],
    keywords: ["pepehands", "pepe", "hands"],
    imageUrl: "/emotes/PepeHands.png",
  },
  {
    name: "pepeJAMJAM",
    short_names: ["pepeJAMJAM"],
    keywords: ["pepejamjam", "pepe", "jam"],
    imageUrl: "/emotes/pepeJAMJAM.gif",
  },
  {
    name: "pepeJAM",
    short_names: ["pepeJAM"],
    keywords: ["pepeJAM", "pepe"],
    imageUrl: "/emotes/pepejam.gif",
  },
  {
    name: "PepeLa",
    short_names: ["PepeLa"],
    keywords: ["pepela", "pepe"],
    imageUrl: "/emotes/PepeLa.png",
  },
  {
    name: "PepeLaugh",
    short_names: ["PepeLaugh"],
    keywords: ["pepelaugh", "pepe", "laugh"],
    imageUrl: "/emotes/PepeLaugh.gif",
  },
  {
    name: "pepeMeltdown",
    short_names: ["pepeMeltdown"],
    keywords: ["pepemeltdown", "pepe", "meltdown"],
    imageUrl: "/emotes/pepeMeltdown.gif",
  },
  {
    name: "pepeP",
    short_names: ["pepeP"],
    keywords: ["pepep", "pepe"],
    imageUrl: "/emotes/pepeP.gif",
  },
  {
    name: "PepeS",
    short_names: ["PepeS"],
    keywords: ["pepes", "pepe"],
    imageUrl: "/emotes/PepeS.gif",
  },
  {
    name: "PepeSpit",
    short_names: ["PepeSpit"],
    keywords: ["pepespit", "pepe", "spit"],
    imageUrl: "/emotes/PepeSpit.gif",
  },
  {
    name: "pepeW",
    short_names: ["pepeW"],
    keywords: ["pepew", "pepe"],
    imageUrl: "/emotes/pepeW.gif",
  },
  {
    name: "PepoG",
    short_names: ["PepoG"],
    keywords: ["pepog", "pepo", "peepo"],
    imageUrl: "/emotes/PepoG.png",
  },
  {
    name: "Pog",
    short_names: ["Pog"],
    keywords: ["pog", "poggies", "pogger", "poggers"],
    imageUrl: "/emotes/Pog.png",
  },
  {
    name: "POGGERS",
    short_names: ["POGGERS"],
    keywords: ["poggers", "pog", "poggies", "pogger"],
    imageUrl: "/emotes/POGGERS.png",
  },
  {
    name: "POGGIES",
    short_names: ["POGGIES"],
    keywords: ["poggies", "pog", "pogger"],
    imageUrl: "/emotes/POGGIES.png",
  },
  {
    name: "PogO",
    short_names: ["PogO"],
    keywords: ["pogo", "pog", "azan"],
    imageUrl: "/emotes/PogO.png",
  },
  {
    name: "PogU",
    short_names: ["PogU"],
    keywords: ["pogu", "pog"],
    imageUrl: "/emotes/PogU.png",
  },
  {
    name: "ppHop",
    short_names: ["ppHop"],
    keywords: ["pphop", "hop"],
    imageUrl: "/emotes/ppHop.gif",
  },
  {
    name: "ppOverheat",
    short_names: ["ppOverheat"],
    keywords: ["ppoverheat", "overheat"],
    imageUrl: "/emotes/ppOverheat.gif",
  },
  {
    name: "ppPoof",
    short_names: ["ppPoof"],
    keywords: ["pppoof", "poof"],
    imageUrl: "/emotes/ppPoof.gif",
  },
  {
    name: "Prayge",
    short_names: ["Prayge"],
    keywords: ["prayge", "pray", "god"],
    imageUrl: "/emotes/Prayge.png",
  },
  {
    name: "ratJAM",
    short_names: ["ratJAM"],
    keywords: ["ratjam", "rat", "jam"],
    imageUrl: "/emotes/ratJAM.gif",
  },
  {
    name: "REE",
    short_names: ["REE"],
    keywords: ["ree", "reeee"],
    imageUrl: "/emotes/REE.png",
  },
  {
    name: "SillyChamp",
    short_names: ["SillyChamp"],
    keywords: ["sillychamp", "silly", "champ"],
    imageUrl: "/emotes/SillyChamp.png",
  },
  {
    name: "SmokeTime",
    short_names: ["SmokeTime"],
    keywords: ["smoketime", "smoke"],
    imageUrl: "/emotes/SmokeTime.gif",
  },
  {
    name: "SWOONER",
    short_names: ["SWOONER"],
    keywords: ["swooner"],
    imageUrl: "/emotes/SWOONER.png",
  },
  {
    name: "TeaTime",
    short_names: ["TeaTime"],
    keywords: ["teatime", "tea"],
    imageUrl: "/emotes/TeaTime.gif",
  },
  {
    name: "TomatoTime",
    short_names: ["TomatoTime"],
    keywords: ["tomatotime", "tomato"],
    imageUrl: "/emotes/TomatoTime.gif",
  },
  {
    name: "unPOGGERS",
    short_names: ["unPOGGERS"],
    keywords: ["unpoggers", "poggers", "pog", "unpoggies"],
    imageUrl: "/emotes/unPOGGERS.png",
  },
  {
    name: "WeirdChamp",
    short_names: ["WeirdChamp"],
    keywords: ["weirdchamp", "weird", "champ"],
    imageUrl: "/emotes/WeirdChamp.png",
  },
  {
    name: "Weirdge",
    short_names: ["Weirdge"],
    keywords: ["weirdge", "weird"],
    imageUrl: "/emotes/Weirdge.png",
  },
  {
    name: "WhatChamp",
    short_names: ["WhatChamp"],
    keywords: ["whatchamp", "what", "champ"],
    imageUrl: "/emotes/WhatChamp.png",
  },
  {
    name: "WICKED",
    short_names: ["WICKED"],
    keywords: ["wicked"],
    imageUrl: "/emotes/WICKED.png",
  },
  {
    name: "widepeepoHappy",
    short_names: ["widepeepoHappy"],
    keywords: ["widepeepohappy", "peepohappy", "peepo", "happy"],
    imageUrl: "/emotes/widepeepoHappy.png",
  },
  {
    name: "widepeepoSad",
    short_names: ["widepeepoSad"],
    keywords: ["widepeeposad", "peeposad", "peepo", "sad"],
    imageUrl: "/emotes/widepeepoSad.png",
  },
  {
    name: "WineTime",
    short_names: ["WineTime"],
    keywords: ["winetime", "wine"],
    imageUrl: "/emotes/WineTime.gif",
  },
  {
    name: "YEP",
    short_names: ["YEP"],
    keywords: ["yep"],
    imageUrl: "/emotes/YEP.png",
  },
  {
    name: "ZZoomer",
    short_names: ["ZZoomer"],
    keywords: ["zzoomer", "zoomer"],
    imageUrl: "/emotes/ZZoomer.gif",
  },
  {
    name: "Doge3D",
    short_names: ["Doge3D"],
    keywords: ["doge3d", "doge", "3d"],
    imageUrl: "/emotes/doge3d.gif",
  },
  {
    name: "DogeCool",
    short_names: ["DogeCool"],
    keywords: ["dogecool", "doge", "cool"],
    imageUrl: "/emotes/dogecool.gif",
  },
  {
    name: "ThugPepe",
    short_names: ["ThugPepe"],
    keywords: ["dogehouse", "doge", "thugpepe"],
    imageUrl: "/emotes/thugpepe.png",
  },
  {
    name: "blaze",
    short_names: ["blaze"],
    keywords: ["blaze", "yes", "snark"],
    imageUrl: "/emotes/blaze.png",
  },
  {
    name: "blobDance",
    short_names: ["blobDance"],
    keywords: ["blobDance", "vibe"],
    imageUrl: "/emotes/blobdance.gif",
  },
  {
    name: "PepePls",
    short_names: ["PepePls"],
    keywords: ["PepePls", "pepe"],
    imageUrl: "/emotes/pepepls.gif",
  },
  {
    name: "FeelsGoodMan",
    short_names: ["FeelsGoodMan"],
    keywords: ["FeelsGoodMan", "pepe"],
    imageUrl: "/emotes/feelsgoodman.png",
  },
  {
    name: "GitHub",
    short_names: ["GitHub"],
    keywords: ["app", "git", "github"],
    imageUrl: "/emotes/github.png",
  },
  {
    name: "Elixir",
    short_names: ["Elixir"],
    keywords: ["app", "elixir"],
    imageUrl: "/emotes/elixir.png",
  },
  {
    name: "NextJS",
    short_names: ["NextJS"],
    keywords: ["app", "next", "js"],
    imageUrl: "/emotes/nextjs.png",
  },
  {
    name: "React",
    short_names: ["React"],
    keywords: ["app", "react", "js"],
    imageUrl: "/emotes/react.png",
  },
  {
    name: "Electron",
    short_names: ["Electron"],
    keywords: ["app", "electron"],
    imageUrl: "/emotes/electron.png",
  },
  {
    name: "BeardGuyR",
    short_names: ["BeardGuyR"],
    keywords: ["meme", "beard", "guy"],
    imageUrl: "/emotes/beardguyright.png",
  },
  {
    name: "BeardGuyL",
    short_names: ["BeardGuyL"],
    keywords: ["meme", "beard", "guy"],
    imageUrl: "/emotes/beardguyleft.png",
  },
  {
    name: "MemeMan",
    short_names: ["MemeMan"],
    keywords: ["meme", "guy"],
    imageUrl: "/emotes/mememan.png",
  },
  {
    name: "NPC",
    short_names: ["NPC"],
    keywords: ["meme", "npc", "guy"],
    imageUrl: "/emotes/npc.png",
  },
  {
    name: "SmudgeCat",
    short_names: ["SMudgeCat"],
    keywords: ["meme", "cat"],
    imageUrl: "/emotes/smudgecat.png",
  },
  {
    name: "Stonks",
    short_names: ["Stonks"],
    keywords: ["meme", "crypto", "guy"],
    imageUrl: "/emotes/stonks.png",
  },
  {
    name: "Stinks",
    short_names: ["Stinks"],
    keywords: ["meme", "crypto", "guy"],
    imageUrl: "/emotes/stinks.png",
  },
  {
    name: "IWMD",
    short_names: ["IWMD"],
    keywords: ["meme", "dude", "wednesday", "frog"],
    imageUrl: "/emotes/wednesday.png",
  },
  {
    name: "PreSponge",
    short_names: ["PreSponge"],
    keywords: ["meme", "spongebob"],
    imageUrl: "/emotes/presponge.png",
  },
  {
    name: "sPonGeBoB",
    short_names: ["sPonGeBoB"],
    keywords: ["meme", "spongebob"],
    imageUrl: "/emotes/sPonGeBoB.png",
  },
  {
    name: "peepohappy",
    short_names: ["peepohappy"],
    keywords: ["peepohappy", "peepo", "pepe"],
    imageUrl: "/emotes/peepohappy.png",
  },
  {
    name: "peepohug",
    short_names: ["peepohug"],
    keywords: ["peepohug", "peepo", "pepe"],
    imageUrl: "/emotes/peepohug.png",
  },
  {
    name: "PurpleDogeHouse",
    short_names: ["PurpleDogeHouse"],
    keywords: ["purple", "dogehouse", "doge"],
    imageUrl: "/emotes/purpledogehouse.png",
  },
  {
    name: "widepeepoPussy",
    short_names: ["widepeepoPussy"],
    keywords: ["widepeepoPussy", "peepo", "wide"],
    imageUrl: "/emotes/widepeepoPussy.png",
  },
  {
    name: "OMEGALUL",
    short_names: ["OMEGALUL"],
    keywords: ["OMEGALUL", "LUL", "OMEGA"],
    imageUrl: "/emotes/omegaLul.png",
  },
  {
    name: "CrabPls",
    short_names: ["CrabPls"],
    keywords: ["Crab", "Pls"],
    imageUrl: "/emotes/crabPls.gif",
  },
  {
    name: "RareParrot",
    short_names: ["RareParrot"],
    keywords: ["Rare", "Parrot", "party"],
    imageUrl: "/emotes/rareParrot.gif",
  },
  {
    name: "COGGERS",
    short_names: ["COGGERS"],
    keywords: ["COG", "COGGERS", "pepe"],
    imageUrl: "/emotes/coggers.gif",
  },
  {
    name: "OrangeDogeHouse",
    short_names: ["OrangeDogeHouse"],
    keywords: ["orange", "dogehouse", "doge"],
    imageUrl: "/emotes/orangedogehouse.png",
  },
  {
    name: "CyanDogeHouse",
    short_names: ["CyanDogeHouse"],
    keywords: ["cyan", "dogehouse", "doge"],
    imageUrl: "/emotes/cyandogehouse.png",
  },
  {
    name: "AngryKermit",
    short_names: ["AngryKermit"],
    keywords: ["kermit", "angry", "ak47"],
    imageUrl: "/emotes/angrykermit.png",
  },
  {
    name: "DarthKermit",
    short_names: ["DarthKermit"],
    keywords: ["kermit", "star", "wars", "darth"],
    imageUrl: "/emotes/darthkermit.png",
  },
  {
    name: "Anime Girl Pepe",
    short_names: ["AnimePepe"],
    keywords: ["pepe", "anime", "girl"],
    imageUrl: "/emotes/pepeanimegirl.png",
  },
  {
    name: "TakeMyMoney",
    short_names: ["TakeMyMoney"],
    keywords: ["take", "my", "money", "shut", "up"],
    imageUrl: "/emotes/takemymoney.png",
  },
  {
    name: "awyeah",
    short_names: ["awyeah"],
    keywords: ["aw", "yeah", "awyeah", "dancing"],
    imageUrl: "/emotes/awyeah.gif",
  },
  {
    name: "ZeroTwoLul",
    short_names: ["ZTlul"],
    keywords: ["lul", "lol", "anime", "girl", "zerotwo"],
    imageUrl: "/emotes/zerotwolul.png",
  },
  {
    name: "ZeroTwoSmug",
    short_names: ["ZTsmug"],
    keywords: ["smug", "anime", "girl", "zerotwo"],
    imageUrl: "/emotes/zerotwosmug.png",
  },
  {
    name: "ZeroTwoThinking",
    short_names: ["ZTthinking"],
    keywords: ["thinking", "think", "anime", "girl", "zerotwo"],
    imageUrl: "/emotes/zerotwothinking.png",
  },
  {
    name: "PartyParrot",
    short_names: ["PartyParrot"],
    keywords: ["party", "high", "parrot", "fun"],
    imageUrl: "/emotes/PartyParrot.gif",
  },
  {
    name: "DogePls",
    short_names: ["DogePls"],
    keywords: ["doge", "please", "fun", "dance"],
    imageUrl: "/emotes/DogePls.gif",
  },
  {
    name: "catDance",
    short_names: ["catDance"],
    keywords: ["cat", "dance", "party", "fun"],
    imageUrl: "/emotes/catDance.gif",
  },
].map((e) => ({ ...e, customCategory: "Custom", text: "", emoticons: [] }));

export const emoteMap: Record<string, string> = {};

export type CustomEmote = EmojiData & {
  customCategory: string;
};

customEmojis.forEach((e) => {
  emoteMap[e.name] = e.imageUrl;
});
